import { useEffect } from 'react';


const InactivityLogoutTimer = () => {
    const checkForInactivity = () => {
        const expireTimeString = localStorage.getItem('expireTime');
        const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;
        if (window.location.pathname !== '/' && expireTime !== 0 && expireTime < Date.now()) {
            localStorage.clear();
            window.location.href='/';
        }
    };

    const updateExpiryTime = () => {
        const expireTime = Date.now()+ 2 * 60 * 60 * 1000;
        localStorage.setItem('expireTime', expireTime.toString());
    };

    useEffect(() => {
        if (localStorage.getItem('expireTime') == null) {
            updateExpiryTime();
        }
        checkForInactivity();
    }, []);

    useEffect(() => {
        const events = ['click', 'keypress', 'scroll', 'mousemove'];
        const expireTime = localStorage.getItem('expireTime');
        var date = new Date(Date.now()).getDate();
        if (expireTime !== null) {
            date = new Date(parseInt(expireTime)).getDate();
        }
        const update = () => {
            if (date === new Date(Date.now()).getDate()) {
                updateExpiryTime();
            }
        };

        events.forEach(event => window.addEventListener(event, update));

        return () => {
            events.forEach(event => window.removeEventListener(event, update));
        };
    }, []);

        return null; 
  };

  export default InactivityLogoutTimer;