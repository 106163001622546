import { UserRole } from 'types';
import { isDev } from 'utils';

const routes = {
  portal: {
    to: '/portal',
    children: 'Portal'
  },
  retailer: {
    to: '/retailer',
    children: 'Återförsäljare'
  },
  users: {
    to: `/users`,
    children: 'Användare'
  },
  warranties: {
    to: '/warranties',
    children: 'Garantiärenden'
  },
  vehicles: {
    to: '/vehicles',
    children: 'Fordonsinfo'
  },
  consignment: {
    to: '/consignment',
    children: 'Konsignationslager'
  },
  exploded: {
    to: '/exploded-views',
    children: 'Sprängskisshantering'
  },
  webshop: {
    to: '/webshop',
    children: 'Webbshop'
  },
  myOrders: {
    to: '/my-orders',
    children: 'Mina ordrar'
  },
  myInvoices: {
    to: '/my-invoices',
    children: 'Mina fakturor'
  },
  showroom: {
    to: '/showroom',
    children: 'Visningsfordon'
  },
  operationCodes: {
    to: '/operation-groups',
    children: 'Operationskoder'
  },
  spareParts: {
    to: '/spare-parts',
    children: 'Reservdelsguiden'
  },
  sales: {
    to:
      'https://www.dropbox.com/sh/2s9ft16n3a78fco/AAAFVVIg9NWhXOtZ1-oAXiyTa?dl=0',
    children: 'Dokument försäljning'
  },
  afterMarket: {
    to:
      'https://www.dropbox.com/scl/fo/fj3g5ez497jrcosbt7lzb/AJgBFNJmWI788-r_9iABxVc?rlkey=6xhpbholevar3mzrorkrcbo7i&st=dji76o76&dl=0',
    children: 'Dokument eftermarknad'
  },
  productRules: {
    to: '/admin/product-rules',
    children: 'Produktregler'
  },
  volumeDiscounts: {
    to: '/admin/volume-discounts',
    children: 'Mängdrabatter'
  },
  maintenanceTasks: {
    to: '/maintenance-tasks',
    children: 'Storkundsärenden'
  },
  customPrices: {
    to: '/custom-prices',
    children: 'Storkundspriser'
  }
};

export const getAvailableLinks = (
  userRole: UserRole,
  showConsignment: boolean,
  showExhibition: boolean,
  showMaintenance: boolean,
) => () => {
  const defaultRoutes = [routes.portal];
  const consignmentRoute = showConsignment ? [routes.consignment] : [];
  const showroomRoute = showExhibition ? [routes.showroom] : [];
  const adminRoutes = [
    routes.productRules,
    routes.volumeDiscounts,
    routes.operationCodes,
    routes.exploded
  ];

  // These are a temporary way of handling new routes during development
  const maintenanceTaskRoutes = showMaintenance ? [routes.maintenanceTasks] : [];
  const maintenanceTaskAdminRoutes = [routes.customPrices];

  switch (userRole) {
    case UserRole.SuperAdmin:
      return defaultRoutes.concat([
        routes.webshop,
        routes.spareParts,
        routes.vehicles,
        ...consignmentRoute,
        ...showroomRoute,
        routes.myOrders,
        routes.myInvoices,
        routes.warranties,
        routes.retailer,
        routes.users,
        routes.sales,
        routes.afterMarket,
        ...maintenanceTaskRoutes,
        ...maintenanceTaskAdminRoutes,
        ...adminRoutes
      ]);
    case UserRole.DealyAdmin:
      return defaultRoutes.concat([
        routes.webshop,
        routes.spareParts,
        routes.vehicles,
        ...consignmentRoute,
        ...showroomRoute,
        routes.myOrders,
        routes.myInvoices,
        routes.warranties,
        routes.sales,
        routes.afterMarket,
        ...maintenanceTaskRoutes,
        ...maintenanceTaskAdminRoutes,
        ...adminRoutes
      ]);
    case UserRole.DealySeller:
      return defaultRoutes.concat([
        routes.webshop,
        routes.spareParts,
        routes.vehicles,
        routes.myOrders,
        routes.sales,
        routes.afterMarket
      ]);
    case UserRole.RetailerAdmin:
      return defaultRoutes.concat([
        routes.webshop,
        routes.spareParts,
        routes.vehicles,
        ...consignmentRoute,
        ...showroomRoute,
        routes.myOrders,
        routes.myInvoices,
        routes.warranties,
        routes.users,
        routes.sales,
        routes.afterMarket,
        ...maintenanceTaskRoutes
      ]);
    case UserRole.RetailerUser:
      return defaultRoutes.concat([
        routes.webshop,
        routes.spareParts,
        routes.vehicles,
        ...consignmentRoute,
        ...showroomRoute,
        routes.myOrders,
        routes.myInvoices,
        routes.warranties,
        routes.sales,
        routes.afterMarket
      ]);
    case UserRole.RetailerSeller:
      return defaultRoutes.concat([
        routes.webshop,
        routes.vehicles,
        ...consignmentRoute,
        ...showroomRoute,
        routes.myOrders,
        routes.myInvoices,
        routes.sales
      ]);
    case UserRole.RetailerWorkshop:
      return defaultRoutes.concat([
        routes.webshop,
        routes.spareParts,
        routes.vehicles,
        routes.myOrders,
        routes.myInvoices,
        routes.warranties,
        routes.afterMarket,
        ...maintenanceTaskRoutes
      ]);
    case UserRole.InsuranceUser:
      return defaultRoutes.concat([routes.vehicles]);
    default:
      return defaultRoutes;
  }
};
